<template>
  <div>
    <swiper
      :loop="true"
      :autoplay="{ delay: 0 }"
      :speed="2500"
      :modules="modules"
      id="program"
      ref="test"
      :breakpoints="{
        '375': {
          slidesPerView: 1,
          spaceBetween: 300,
          centeredSlides: true,
        },
        '525': {
          slidesPerView: 1,
          spaceBetween: 90,
          centeredSlides: true,
        },
        '1024': {
          slidesPerView: 2,
          spaceBetween: 460,
          centeredSlides: true,
        },
        '1400': {
          slidesPerView: 2,
          spaceBetween: 60,
        },
        '1600': {
          slidesPerView: 3,
          spaceBetween: 600,
        },
        '1800': {
          slidesPerView: 3,
          spaceBetween: 380,
        },
      }"
    >
      <swiper-slide class="program-title">Program</swiper-slide>
      <swiper-slide class="program-title">Program</swiper-slide>
      <swiper-slide class="program-title">Program</swiper-slide>
      <swiper-slide class="program-title">Program</swiper-slide>
    </swiper>
    <div class="program-container-outer">
      <div class="program-container">
        <div class="program-day-box">
          <div class="program-day-box-number">
            <span class="day-name-box">
              <span class="day-name">Dzień</span>
              <span class="day-date">23.11.2022</span>
            </span>
            <span class="day-number">1</span>
            <ul class="day-list">
              <li class="day-desctription">
                10:15-10:45 I Prelekcja Design Thinking
              </li>
              <li class="day-desctription">10:55-11:25 II Prelekcja UX</li>
              <li class="day-desctription">
                11:35-12:05 III Prelekcja Change Management
              </li>
              <li class="day-desctription">
                13:50-14:20 IV Prelekcja Change Management
              </li>
              <li class="day-desctription">14:30-15:00 V Prelekcja UX</li>
              <li class="day-desctription">
                15:10-15:40 VI Prelekcja Design Thinking
              </li>
            </ul>
          </div>
        </div>
        <div class="program-day-box">
          <div class="program-day-box-number">
            <span class="day-name-box">
              <span class="day-name">Dzień</span>
              <span class="day-date">24.11.2022</span>
            </span>
            <span class="day-number">2</span>
            <ul class="day-list">
              <li class="day-desctription" style="margin-left: 2rem">
                10:15-10:45 I Prelekcja Change Management
              </li>
              <li class="day-desctription" style="margin-left: -12rem">
                10:55-11:25 II Prelekcja Design Thinking
              </li>
              <li class="day-desctription" style="margin-left: 5rem">
                11:35-12:05 III Prelekcja UX
              </li>
              <li class="day-desctription" style="margin-left: -6rem">
                13:50-14:20 IV Prelekcja Design Thinking
              </li>
              <li class="day-desctription" style="margin-left: -1rem">
                14:30-15:00 V Prelekcja Change Management
              </li>
              <li class="day-desctription" style="margin-left: -12rem">
                15:10-15:40 VI Prelekcja UX
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  setup() {
    return {
      modules: [Autoplay],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },

  methods: {
    onScroll(e) {
      this.windowTop = window.top.scrollY;
      const programSection = document.querySelector(".program-container");
      const programSectionTop = programSection.getBoundingClientRect().top;
      const programSectionOuter = document.querySelector(
        ".program-container-outer"
      );

      if (programSectionTop <= 5) {
        //document.body.classList.add("stop-scrolling");

        window.removeEventListener("scroll", this.onScroll);

        const pageYPosition = window.pageYOffset;
        const pageXPosition = window.pageXOffset;

        window.addEventListener("scroll", () => {
          window.scrollTo(pageXPosition, pageYPosition);
          const programSection = document.querySelector(".program-container");
          const programSectionOuter = document.querySelector(
            ".program-container-outer"
          );
          programSectionOuter.scrollLeft += 20;
          console.log(
            "Program Section Outer: " +
              programSectionOuter.getBoundingClientRect().right
          );
          console.log(
            "Program Section: " + programSection.getBoundingClientRect().right
          );
          if (
            programSection.getBoundingClientRect().right <=
            programSectionOuter.getBoundingClientRect().right
          ) {
            window.addEventListener("scroll", () => {
              this.test();
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
.program-title {
  font-size: 140px;
  line-height: 180px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ee3a5b;
  text-align: center;
  &:nth-child(2n) {
    color: #6868ac;
  }
  @media screen and (max-width: 820px) {
    font-size: 120px;
    line-height: 90px;
  }
  @media screen and (max-width: 500px) {
    font-size: 67px;
    line-height: 89px;
  }
}
.program-container-outer {
  overflow-x: auto;
}
.program-container {
  background: #262e6c;
  width: 3000px;
  height: 105vh;
  display: flex;
  .program-day-box {
    width: 1500px;
    background-image: url("../assets/ProgramSection/ProgramSectionBg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    &-number {
      display: flex;
      align-items: center;
      margin-left: 1rem;
      .day-name-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        .day-name {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #f6f6f6;
        }
        .day-date {
          margin-top: 0.5rem;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #f6f6f6;
        }
      }
      .day-number {
        background: linear-gradient(173.12deg, #ee3a5b 14.4%, #f7f7f7 83.49%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        display: flex;
        align-items: center;
        text-align: center;
        font-weight: 400;
        font-size: 200px;
        line-height: 244px;
      }
    }
    .day-list {
      display: flex;
      list-style: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #f6f6f6;
      text-align: center;
      .day-desctription:nth-child(1) {
        margin-top: 5rem;
        margin-left: 4.5rem;
      }
      .day-desctription:nth-child(2) {
        margin-top: 25rem;
        margin-left: -9rem;
      }
      .day-desctription:nth-child(3) {
        margin-top: 11rem;
        margin-left: 11rem;
      }
      .day-desctription:nth-child(4) {
        margin-top: 34rem;
        margin-left: -14rem;
      }
      .day-desctription:nth-child(5) {
        margin-top: -0.5rem;
        margin-left: -4rem;
      }
      .day-desctription:nth-child(6) {
        margin-top: 22rem;
        margin-left: -3rem;
        margin-right: 3rem;
      }
    }
  }
}
</style>
