<script>
export default {
  data() {
    const jsonld = {
      head: {
        title: "I DESIGN Konferencja Marketingowa",
        meta: [
          { charset: "utf-8" },
          { name: "viewport", content: "width=device-width, initial-scale=1" },
          { name: "msapplication-TileColor", content: "#ffffff" },
          {
            name: "msapplication-TileImage",
            content: "/favi/ms-icon-144x144.png",
          },
          { name: "theme-color", content: "#ffffff" },
          { name: "title", content: "I DESIGN Konferencja Marketingowa" },
          {
            name: "description",
            content:
              "VI edycja Konferencji Marketingowej I DESIGN w formie hybrydowej",
          },
          {
            property: "og:image",
            content: "https://i-design.com.pl/img/logo-i-design.png",
          },
          { property: "og:image:type", content: "png" },
          { property: "og:image:width", content: "960" },
          { property: "og:image:height", content: "560" },
          {
            property: "og:title",
            content: "I DESIGN Konferencja Marketingowa Online",
          },
          {
            property: "og:description",
            content:
              "VI edycja Konferencji Marketingowej I DESIGN odbędzie się w formie hybrydowej.",
          },
          {
            name: "facebook-domain-verification",
            content: "spww5sbmikpfl7hiug9b1ivefx8zd9",
          },
        ],
        link: [
          {
            rel: "apple-touch-icon",
            sizes: "57x57",
            href: "/favi/apple-icon-57x57.png",
          },
          {
            rel: "apple-touch-icon",
            sizes: "60x60",
            href: "/favi/apple-icon-60x60.png",
          },
          {
            rel: "apple-touch-icon",
            sizes: "72x72",
            href: "/favi/apple-icon-72x72.png",
          },
          {
            rel: "apple-touch-icon",
            sizes: "76x76",
            href: "/favi/apple-icon-76x76.png",
          },
          {
            rel: "apple-touch-icon",
            sizes: "114x114",
            href: "/favi/apple-icon-114x114.png",
          },
          {
            rel: "apple-touch-icon",
            sizes: "120x120",
            href: "/favi/apple-icon-120x120.png",
          },
          {
            rel: "apple-touch-icon",
            sizes: "144x144",
            href: "/favi/apple-icon-144x144.png",
          },
          {
            rel: "apple-touch-icon",
            sizes: "152x152",
            href: "/favi/apple-icon-152x152.png",
          },
          {
            rel: "apple-touch-icon",
            sizes: "180x180",
            href: "/favi/apple-icon-180x180.png",
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "192x192",
            href: "/favi/android-icon-192x192.png",
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: "/favi/favicon-32x32.png",
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "96x96",
            href: "/favi/favicon-96x96.png",
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "16x16",
            href: "/favi/favicon-16x16.png",
          },
          { rel: "manifest", href: "/favi/manifest.json" },
          { rel: "image_src", href: "/img/logo-i-design.svg" },
        ],
        script: [
          {
            src: "https://identity.netlify.com/v1/netlify-identity-widget.js",
            defer: true,
            id: "netlify-identity-widget-script",
          },
          {
            type: "application/ld+json",
            json: {
              "@context": "http://schema.org",
              "@type": "Organization",
              name: "Konferencja marketingowa IDesign",
              url: "https://i-design.com.pl/",
              logo: "https://i-design.com.pl/img/logo-i-design.svg",
              telephone: "+48 661 574 969",
              email: "kontakt@i-design.com.pl",
              sameAs: [
                "https://www.linkedin.com/company/konferencjaidesign/",
                "https://m.facebook.com/KONFERENCJAIDESIGN/",
                "https://www.instagram.com/konferencjaidesign/",
              ],
            },
          },
        ],
      },
    };
    return {
      jsonld,
    };
  },
};
</script>
<template></template>
