<template>
  <section class="meet-us-section">
    <h3 class="meet-us">
      <span class="red-text">Poznaj nas</span> - kim jesteśmy w I DESIGN, co
      robimy w życiu, czym się interesujemy i jakie słowo nas określa. Dołącz do
      nas i powiedz nam coś o sobie
    </h3>
    <div class="our-team-container">
      <div class="our-team" v-if="showTeam">
        <div class="team-member">
          <img
            class="team-member-photo"
            src="../assets/MeetUsSection/monika-drabczynska.svg"
            alt=""
          />
          <div class="team-member-info">
            <p class="team-member-name">Monika Drabczyńska</p>
            <ul class="team-member-details">
              <li>#prezeska</li>
              <li>#strateg</li>
              <li>#zadawaniepytań</li>
              <li>#wyjściezestrefykomfortu</li>
            </ul>
          </div>
        </div>
        <div class="team-member">
          <img
            class="team-member-photo"
            src="../assets/MeetUsSection/ola-rdzanek.svg"
            alt=""
          />
          <div class="team-member-info">
            <p class="team-member-name">Ola Rdzanek</p>
            <ul class="team-member-details">
              <li>#administracja</li>
              <li>#wdrożenia i rozwój aplikacji</li>
              <li>#taniec</li>
              <li>#dociekliwość</li>
            </ul>
          </div>
        </div>
        <div class="team-member">
          <img
            class="team-member-photo"
            src="../assets/MeetUsSection/monika-misiak.svg"
            alt=""
          />
          <div class="team-member-info">
            <p class="team-member-name">Monika Misiak</p>
            <ul class="team-member-details">
              <li>#promocja</li>
              <li>#marketing</li>
              <li>#pandy</li>
              <li>#empatia</li>
            </ul>
          </div>
        </div>
        <div class="team-member">
          <img
            class="team-member-photo"
            src="../assets/MeetUsSection/kasia-gomula.svg"
            alt=""
          />
          <div class="team-member-info">
            <p class="team-member-name">Kasia Gomula</p>
            <ul class="team-member-details">
              <li>#promocja</li>
              <li>#eventmarketing</li>
              <li>#sztuka</li>
              <li>#cierpliwość</li>
            </ul>
          </div>
        </div>
        <div class="team-member">
          <img
            class="team-member-photo"
            src="../assets/MeetUsSection/konstancja-tanjga.svg"
            alt=""
          />
          <div class="team-member-info">
            <p class="team-member-name">Konstancja Tanjga</p>
            <ul class="team-member-details">
              <li>#ux/ui designer</li>
              <li>#design</li>
              <li>#triathlon</li>
              <li>#chihuahua</li>
              <li>#ciekawość</li>
            </ul>
          </div>
        </div>
        <div class="team-member">
          <img
            class="team-member-photo"
            src="../assets/MeetUsSection/julia-ptak.svg"
            alt=""
          />
          <div class="team-member-info">
            <p class="team-member-name">Julia Ptak</p>
            <ul class="team-member-details">
              <li>#administracja</li>
              <li>#psychologiawbiznesie</li>
              <li>#wszystkoinic</li>
              <li>#renesans</li>
            </ul>
          </div>
        </div>
        <div class="team-member">
          <img
            class="team-member-photo"
            src="../assets/MeetUsSection/nikola-blania.svg"
            alt=""
          />
          <div class="team-member-info">
            <p class="team-member-name">Nikola Blania</p>
            <ul class="team-member-details">
              <li>#administracja</li>
              <li>#dataAnalytics</li>
              <li>#bodyLanguage</li>
              <li>#curiosity&development</li>
            </ul>
          </div>
        </div>
        <div class="team-member">
          <img
            class="team-member-photo"
            src="../assets/MeetUsSection/jagoda-mrozek.svg"
            alt=""
          />
          <div class="team-member-info">
            <p class="team-member-name">Jagoda Mrózek</p>
            <ul class="team-member-details">
              <li>#relacje</li>
              <li>#zzl</li>
              <li>#świat</li>
              <li>#wielozadaniowość</li>
            </ul>
          </div>
        </div>
        <div class="team-member">
          <img
            class="team-member-photo"
            src="../assets/MeetUsSection/marek-kuzniar.svg"
            alt=""
          />
          <div class="team-member-info">
            <p class="team-member-name">Marek Kuźniar</p>
            <ul class="team-member-details">
              <li>#it</li>
              <li>#it</li>
              <li>#it</li>
              <li>#it</li>
            </ul>
          </div>
        </div>
        <div class="team-member">
          <img
            class="team-member-photo"
            src="../assets/MeetUsSection/krzysztof-chodziak.svg"
            alt=""
          />
          <div class="team-member-info">
            <p class="team-member-name">Krzysztof Chodziak</p>
            <ul class="team-member-details">
              <li>#it</li>
              <li>#it</li>
              <li>#it</li>
              <li>#it</li>
            </ul>
          </div>
        </div>
        <div class="team-member">
          <img
            class="team-member-photo"
            src="../assets/MeetUsSection/new_person.svg"
            alt=""
          />
          <div class="team-member-info">
            <p class="team-member-name">Czekamy na Ciebie! Zgłoś się</p>
            <ul class="team-member-details">
              <li>#...</li>
              <li>#...</li>
              <li>#...</li>
              <li>#...</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      showTeam: true,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/MeetUsSection.scss";
</style>
