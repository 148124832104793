<template>
  <section class="contact-us-section" id="contact-section">
    <img
      class="cube-element"
      src="../assets/ContactSection/cube_element.svg"
      alt=""
    />
    <h2 class="contact-us-title">Zapraszamy do kontaktu</h2>
    <div class="persons-container">
      <div class="contact-person">
        <img
          class="person-photo"
          src="../assets/ContactSection/katarzyna-gomula.svg"
          alt=""
        />
        <p class="person-position">Koordynatorka ds. Promocji</p>
        <p class="person-name">Katarzyna Gomuła</p>
        <p class="person-phone">
          <img
            class="phone-icon"
            src="../assets/ContactSection/phone-icon.svg"
            alt=""
          />
          +48 733 113 307
        </p>
        <p class="person-mail">
          <img
            class="mail-icon"
            src="../assets/ContactSection/mail-icon.svg"
            alt=""
          />
          katarzynagomula@i-design.com.pl
        </p>
      </div>
      <div class="contact-person">
        <img
          class="person-photo"
          src="../assets/ContactSection/monika-drabczynska.svg"
          alt=""
        />
        <p class="person-position">Koordynator Projektu</p>
        <p class="person-name">Monika Drabczyńska</p>
        <p class="person-phone">
          <img
            class="phone-icon"
            src="../assets/ContactSection/phone-icon.svg"
            alt=""
          />
          +48 661 574 969
        </p>
        <p class="person-mail">
          <img
            class="mail-icon"
            src="../assets/ContactSection/mail-icon.svg"
            alt=""
          />
          monikadrabczynska@i-design.com.pl
        </p>
      </div>
    </div>
    <h3 class="join-us">Napisz do nas i dołącz do naszego grona!</h3>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../assets/style/ContactSection.scss";
</style>
