import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutUs from "../views/AboutUs.vue";
import KnowledgeZone from "../views/KnowledgeZone.vue";
import NotFound from "../views/404.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      hideNavbar: false,
      hideFooter: false,
      hideNotFound: true,
    },
  },
  {
    path: "/o-nas",
    name: "about",
    component: AboutUs,
    meta: {
      hideNavbar: false,
      hideFooter: false,
      hideNotFound: true,
    },
  },
  {
    path: "/strefa-wiedzy",
    name: "knowledge",
    component: KnowledgeZone,
    meta: {
      hideNavbar: false,
      hideFooter: false,
      hideNotFound: true,
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "not-found",
    component: NotFound,
    meta: {
      hideNavbar: true,
      hideFooter: true,
      hideNotFound: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else {
      return { top: 0 };
    }
  },
});

export default router;
