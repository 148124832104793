<template>
  <teleport to="head">
    <component :is="'script'" type="application/ld+json">
      head: { title: "I DESIGN Konferencja Marketingowa", meta: [ { charset:
      "utf-8" }, { name: "viewport", content: "width=device-width,
      initial-scale=1" }, { name: "msapplication-TileColor", content: "#ffffff"
      }, { name: "msapplication-TileImage", content:
      "/favi/ms-icon-144x144.png", }, { name: "theme-color", content: "#ffffff"
      }, { name: "title", content: "I DESIGN Konferencja Marketingowa" }, {
      name: "description", content: "VI edycja Konferencji Marketingowej I
      DESIGN w formie hybrydowej", }, { property: "og:image", content:
      "https://i-design.com.pl/img/logo-i-design.png", }, { property:
      "og:image:type", content: "png" }, { property: "og:image:width", content:
      "960" }, { property: "og:image:height", content: "560" }, { property:
      "og:title", content: "I DESIGN Konferencja Marketingowa Online", }, {
      property: "og:description", content: "VI edycja Konferencji Marketingowej
      I DESIGN odbędzie się w formie hybrydowej.", }, { name:
      "facebook-domain-verification", content: "spww5sbmikpfl7hiug9b1ivefx8zd9",
      }, ], link: [ { rel: "apple-touch-icon", sizes: "57x57", href:
      "/favi/apple-icon-57x57.png", }, { rel: "apple-touch-icon", sizes:
      "60x60", href: "/favi/apple-icon-60x60.png", }, { rel: "apple-touch-icon",
      sizes: "72x72", href: "/favi/apple-icon-72x72.png", }, { rel:
      "apple-touch-icon", sizes: "76x76", href: "/favi/apple-icon-76x76.png", },
      { rel: "apple-touch-icon", sizes: "114x114", href:
      "/favi/apple-icon-114x114.png", }, { rel: "apple-touch-icon", sizes:
      "120x120", href: "/favi/apple-icon-120x120.png", }, { rel:
      "apple-touch-icon", sizes: "144x144", href:
      "/favi/apple-icon-144x144.png", }, { rel: "apple-touch-icon", sizes:
      "152x152", href: "/favi/apple-icon-152x152.png", }, { rel:
      "apple-touch-icon", sizes: "180x180", href:
      "/favi/apple-icon-180x180.png", }, { rel: "icon", type: "image/png",
      sizes: "192x192", href: "/favi/android-icon-192x192.png", }, { rel:
      "icon", type: "image/png", sizes: "32x32", href:
      "/favi/favicon-32x32.png", }, { rel: "icon", type: "image/png", sizes:
      "96x96", href: "/favi/favicon-96x96.png", }, { rel: "icon", type:
      "image/png", sizes: "16x16", href: "/favi/favicon-16x16.png", }, { rel:
      "manifest", href: "/favi/manifest.json" }, { rel: "image_src", href:
      "/img/logo-i-design.svg" }, ], script: [ { src:
      "https://identity.netlify.com/v1/netlify-identity-widget.js", defer: true,
      id: "netlify-identity-widget-script", }, { type: "application/ld+json",
      json: { "@context": "http://schema.org", "@type": "Organization", name:
      "Konferencja marketingowa IDesign", url: "https://i-design.com.pl/", logo:
      "https://i-design.com.pl/img/logo-i-design.svg", telephone: "+48 661 574
      969", email: "kontakt@i-design.com.pl", sameAs: [
      "https://www.linkedin.com/company/konferencjaidesign/",
      "https://m.facebook.com/KONFERENCJAIDESIGN/",
      "https://www.instagram.com/konferencjaidesign/", ], }, }, ], },
    </component>
  </teleport>
  <MainNav v-if="!$route.meta.hideNavbar" />
  <Footer v-if="!$route.meta.hideFooter" />
  <router-view v-if="!$route.meta.hideNotFound" />
</template>

<script>
import MainNav from "../src/components/MainNav.vue";
import Footer from "../src/components/Footer.vue";
import Component from "../src/components/component.vue";
export default {
  components: { MainNav, Footer, Component },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import "assets/style/colors";
* {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
}
h1,
h2,
h3,
h4 {
  color: $mainColor;
}
h1 {
  font-size: 140px;
  font-weight: 700;
  line-height: 137px;
  letter-spacing: 0em;
  opacity: 0.6;
}
h2 {
  font-size: 100px;
  font-weight: 700;
  line-height: 98px;
  letter-spacing: 0em;
}
h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}
h4 {
  font-size: 18px;
  font-weight: 250;
  line-height: 24px;
  letter-spacing: 0em;
}
.red-text {
  color: #ee3a5b;
}
.very-peri-text {
  color: #6868ac;
}
.blue-bold-text {
  font-weight: bold;
  color: #262e6c;
}
.default-button {
  border: none;
  box-shadow: 6px 6px 6px 0 #262e6c;
  background: $activeColor;
  height: 51.93951416015625px;
  width: 257.25px;
  left: 38px;
  top: 3px;
  font-weight: 500;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  color: #f6f6f6;
  width: 333px;
  border-radius: 50px;
  transition: all 0.1s ease-in-out;
  @media screen and (max-width: 916px) {
    font-size: 20px;
    width: 250px;
  }
  &:hover {
    color: $activeColor;
    background: $buttonActiveBg;
    cursor: pointer;
  }
  &:active {
    color: $activeColor;
    background: $buttonActiveBg;
    border: solid $activeColor 5px;
  }
  a {
    text-decoration: none;
    color: #f6f6f6;
  }
}
@media screen and (max-width: 720px) {
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 13.5px;
  }
}
</style>
