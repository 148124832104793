<template>
  <suspense>
      <KnowledgeZoneBlog />
  </suspense>
</template>

<script>
import KnowledgeZoneBlog from "../components/KnowledgeZoneBlog.vue";

export default {
  components: {
    KnowledgeZoneBlog,
  },
};
</script>

<style></style>
