<template>
  <NotFound />
</template>

<script>
import NotFound from "../components/NotFound.vue";
export default {
  components: {
    NotFound,
  },
};
</script>

<style></style>
