<template>
  <section class="speaker-section" id="speakers">
    <div class="speaker-section-bg">
      <swiper
        :loop="true"
        :autoplay="{ delay: 0 }"
        :speed="2500"
        :modules="modules"
        :breakpoints="{
          '375': {
            slidesPerView: 1,
            spaceBetween: 300,
            centeredSlides: true,
          },
          '525': {
            slidesPerView: 1,
            spaceBetween: 90,
            centeredSlides: true,
          },
          '1024': {
            slidesPerView: 2,
            spaceBetween: 760,
            centeredSlides: true,
          },
          '1400': {
            slidesPerView: 2,
            spaceBetween: 360,
          },
          '1600': {
            slidesPerView: 2,
            spaceBetween: 200,
          },
          '1800': {
            slidesPerView: 2,
            spaceBetween: 0,
          },
        }"
      >
        <swiper-slide class="section-title">Prelegenci</swiper-slide>
        <swiper-slide class="section-title">Prelegenci</swiper-slide>
        <swiper-slide class="section-title">Prelegenci</swiper-slide>
        <swiper-slide class="section-title">Prelegenci</swiper-slide>
      </swiper>
      <swiper
        class="speaker-swiper"
        :slidesPerView="4"
        :spaceBetween="30"
        :loop="true"
        :navigation="true"
        :modules="modules"
        :breakpoints="{
          '375': {
            slidesPerView: 1,
          },
          '500': {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          '670': {
            slidesPerView: 2,
            spaceBetween: 50,
          },
          '1080': {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          '1450': {
            slidesPerView: 4,
          },
          '1800': {
            slidesPerView: 4,
          },
        }"
      >
        <swiper-slide class="speaker-box">
          <div class="speaker-photo">
            <img src="../assets/SpeakerSection/maria-lenczuk.jpg" alt="" />
            <div class="speaker-photo-box">
              <img
                class="linkedin-icon"
                src="../assets/SpeakerSection/linkedin-icon.svg"
                alt=""
              />
              <span class="company-name">Bank PKO</span>
            </div>
          </div>
          <div class="speaker-info">
            <span class="speaker-name">Maria Leńczuk</span>
            <span class="speaker-position">Marketing Manadżer</span>
            <span class="speaker-desctription"
              >Wprowadzenie design systemu na przykładzie Gmail</span
            >
          </div>
        </swiper-slide>
        <swiper-slide class="speaker-box">
          <div class="speaker-photo">
            <img src="../assets/SpeakerSection/lukasz-ogrodniczak.jpg" alt="" />
            <div class="speaker-photo-box">
              <img
                class="linkedin-icon"
                src="../assets/SpeakerSection/linkedin-icon.svg"
                alt=""
              />
              <span class="company-name">Allegro</span>
            </div>
          </div>
          <div class="speaker-info">
            <span class="speaker-name">Łukasz Ogrodniczak</span>
            <span class="speaker-position">UX Designer</span>
            <span class="speaker-desctription"
              >Co UX designerzy powinni wiedzieć na temat design thinking?</span
            >
          </div>
        </swiper-slide>
        <swiper-slide class="speaker-box">
          <div class="speaker-photo">
            <img src="../assets/SpeakerSection/katarzyna-ziobro.jpg" alt="" />
            <div class="speaker-photo-box">
              <img
                class="linkedin-icon"
                src="../assets/SpeakerSection/linkedin-icon.svg"
                alt=""
              />
              <span class="company-name">Coca Cola</span>
            </div>
          </div>
          <div class="speaker-info">
            <span class="speaker-name">Katarzyna Ziobro</span>
            <span class="speaker-position">Design Thinking</span>
            <span class="speaker-desctription"
              >Rynek ubezpieczeń po awarii w elektrowni Fukushima.</span
            >
          </div>
        </swiper-slide>
        <swiper-slide class="speaker-box">
          <div class="speaker-photo">
            <img src="../assets/SpeakerSection/maryla-rodowicz.jpg" alt="" />
            <div class="speaker-photo-box">
              <img
                class="linkedin-icon"
                src="../assets/SpeakerSection/linkedin-icon.svg"
                alt=""
              />
              <span class="company-name">Tesla</span>
            </div>
          </div>
          <div class="speaker-info">
            <span class="speaker-name">Maryla Rodowicz</span>
            <span class="speaker-position">UX Designer</span>
            <span class="speaker-desctription"
              >Święto pracy a zamieszki na Saskiej Kępie - jak wpłynęło to na
              bzy?</span
            >
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";
import "swiper/scss";
import "swiper/scss/navigation";
import { Navigation } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation, Autoplay],
    };
  },
};
</script>

<style lang="scss">
.speaker-section {
  background: #262e6c;
  &-bg {
    background-image: url("../assets/SpeakerSection/speaker-section-bg.svg");
    background-repeat: no-repeat;
    min-height: 100vh;
    .section-title {
      pointer-events: none;
      font-size: 140px;
      font-weight: 700;
      line-height: 137px;
      color: #f6f6f6;
      padding-bottom: 1rem;
      text-transform: uppercase;
      text-align: center;
      &:nth-child(2n) {
        color: #6868ac;
      }

      @media screen and (max-width: 920px) {
        font-size: 95px;
        line-height: 90px;
      }
      @media screen and (max-width: 650px) {
        font-size: 83px;
        line-height: 89px;
      }
      @media screen and (max-width: 500px) {
        font-size: 53px;
        line-height: 89px;
      }
    }
    .speaker-swiper {
      margin-top: 4rem;
      .swiper-button-prev {
        color: #f6f6f6;
        &::after {
          height: 6vh;
          background: linear-gradient(
            90deg,
            #6868ac 0%,
            rgba(104, 104, 172, 0.6) 117.24%
          );
          border-radius: 0px 60px 60px 0px;
          padding-top: 12rem;
          padding-bottom: 12rem;
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }
      .swiper-button-next {
        color: #f6f6f6;
        &::after {
          height: 6vh;
          background: linear-gradient(
            90deg,
            #6868ac 0%,
            rgba(104, 104, 172, 0.6) 117.24%
          );
          border-radius: 60px 0px 0px 60px;
          padding-top: 12rem;
          padding-bottom: 12rem;
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }
      .speaker-box {
        display: flex;
        flex-direction: column;
        .speaker-photo {
          position: relative;
          align-self: center;
          width: 323px;
          height: 338px;
          border: solid white 2px;
          border-radius: 20px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 150%;
            height: 340px;
          }
          .speaker-photo-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            content: "";
            bottom: 0;
            left: 0;
            width: 100%;
            height: 30px;
            padding: 1.5rem;
            border-radius: 0px 0px 20px 20px;
            background: rgba($color: #6868ac, $alpha: 0.6);
            .linkedin-icon {
              width: 30px;
              height: 30px;
            }
            .company-name {
              color: #f6f6f6;
              font-size: 20px;
              font-weight: 400;
              line-height: 31px;
            }
          }
        }
        .speaker-info {
          display: flex;
          flex-direction: column;
          margin-left: 5%;
          margin-top: 1rem;
          color: #f6f6f6;

          .speaker-name {
            font-size: 24px;
            font-weight: 400;
            line-height: 24px;
          }
          .speaker-position {
            font-size: 16px;
            font-weight: 100;
            line-height: 30px;
            opacity: 0.8;
          }
          .speaker-description {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }
  }
}
</style>
