<template>
  <section class="sponsors-section">
    <img
      class="cube-elements"
      src="../assets/SponsorsSection/cube_elements.svg"
      alt=""
    />
    <h2 class="sponsors-section-title">Sponsor Love!</h2>
    <div class="main-sponsor">
      <h3 class="main-sponsor-title">Główny partner wydarzenia</h3>
      <a href="https://www.nokia.com/"
        ><img src="../assets/SponsorsSection/nokia_logo.svg" alt=""
      /></a>
    </div>
    <div class="partners">
      <h3 class="partners-title">Partnerzy</h3>
      <div class="partners-box">
        <!--
        <img
          v-for="(logo, index) in sponsors"
          :src="logo.sponsorLogo"
          :alt="logo.alt"
          class="partner-logo"
        />
        -->
        <a href="https://www.shoper.pl/">
          <img
            src="../assets/SponsorsSection/shoper_logo.svg"
            alt="shoper"
            class="partner-logo"
          />
        </a>
        <a href="https://prowly.com/">
          <img
            src="../assets/SponsorsSection/prowly_logo.svg"
            alt="prowly"
            class="partner-logo"
          />
        </a>
        <a href="https://www.grupa-icea.pl/">
          <img
            src="../assets/SponsorsSection/icea_logo.svg"
            alt="icea"
            class="partner-logo"
          />
        </a>
      </div>
    </div>
    <p class="more-info">
      Zostań naszym Sponsorem -
      <span class="red-text">zgłoś się i wesprzyj I DESIGN.</span>
    </p>
    <p class="more-info">
      Działamy jako wolontariusze, więc całość wsparcia od sponsorów
      przekazujemy na rozwój Stowarzyszenia, pokrywając koszty administracyjne
      oraz promocyjne.
    </p>
  </section>
</template>

<script>
export default {
  data() {
    return {
      sponsors: [
        {
          sponsorLogo: "../assets/SponsorsSection/shoper_logo.svg",
          alt: "shoper",
        },
        {
          sponsorLogo: "../assets/SponsorsSection/prowly_logo.svg",
          alt: "prowly",
        },
        {
          sponsorLogo: "../assets/SponsorsSection/icea_logo.svg",
          alt: "icea",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.sponsors-section {
  display: flex;
  flex-direction: column;
  background: #fff;
  min-height: 100vh;
  .cube-elements {
    position: absolute;
    right: 0;
    margin-top: -8rem;
    @media screen and (max-width: 610px) {
      margin-top: 8rem;
    }
  }
  .sponsors-section-title {
    text-align: center;
    position: relative;
    z-index: 2;
    align-self: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 61.0598px;
    line-height: 150.02%;
    color: rgba(38, 46, 108, 0.8);
    opacity: 0.6;
  }
  .main-sponsor {
    margin-top: 10rem;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .main-sponsor-title {
      text-align: center;
      text-transform: uppercase;
      color: rgba(238, 58, 91, 0.8);
      font-weight: 400;
      font-size: 27px;
      line-height: 33px;
      margin-bottom: 2rem;
    }
  }
  .partners {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10rem;
    align-self: center;
    .partners-title {
      font-weight: 400;
      font-size: 27px;
      line-height: 33px;
      color: rgba(238, 58, 91, 0.8);
    }
    .partners-box {
      margin-bottom: 10rem;
      margin-top: 3rem;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      width: 80%;
      .partner-logo {
        margin-bottom: 3rem;
        margin-left: 2rem;
        margin-right: 2rem;
      }
    }
  }
  .more-info {
    align-self: center;
    color: rgba(38, 46, 108, 0.8);
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    width: 60%;
    margin-bottom: 2rem;
  }
}
</style>
