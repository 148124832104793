<template>
  <section class="knowledge-zone-blog">
    <!-- TODO get from rest -->
    <!-- <div class="blog-category-menu">
      <h3 class="blog-category">Marketing X</h3>
      <h3 class="blog-category">Design X</h3>
      <h3 class="blog-category">Agile X</h3>
    </div> -->
    <div class="blogs-container" v-if="posts.length">
      <div class="blog" v-for="post of posts" v-bind:key="post">
        <div class="blog-photo">
          <img :src="post.yoast_head_json.og_image[0].url" alt="" />
        </div>
        <div class="blog-info">
          <p>Tytuł: {{post.title.rendered}}</p>
          <p>Data: {{post.date}}</p>
        </div>
      </div>
    </div>
    <h3 class="more-blog-posts">Więcej</h3>
  </section>
</template>

<script>
import { getPosts } from "../rest/blog";

export default {
  data() {
    return {
      posts: [],
    };
  },
  async setup() {
    const response = await getPosts();
      return {
        posts: response.data,
      };
  }
};
</script>

<style lang="scss" scoped>
.knowledge-zone-blog {
  background: #262e6c;
  background-image: url("../assets/KnowledgeZoneBlog/blogElements.svg");
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  .blog-category-menu {
    display: flex;
    margin-top: 6rem;
    margin-bottom: 6rem;
    margin-left: 4.5rem;
    @media screen and (max-width: 610px) {
      justify-content: space-between;
      margin-left: 1.5rem;
    }
    .blog-category {
      font-weight: 400;
      font-size: 20px;
      line-height: 110%;
      color: #fff;
      margin-right: 5rem;
      @media screen and (max-width: 610px) {
        margin-right: 3rem;
      }
      @media screen and (max-width: 462px) {
        font-size: 15px;
      }
      @media screen and (max-width: 392px) {
        margin-right: 1.5rem;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .blogs-container {
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .blog {
      display: flex;
      flex-direction: column;
      padding: 1.8rem;
      &-info {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #f6f6f6;
      }
    }
  }
  .more-blog-posts {
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    color: #fff;
    align-self: center;
    margin-top: 2rem;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
