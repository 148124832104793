<template>
  <section>
    <div class="conference-section">
      <img
        class="conference-cube"
        src="../assets/conferencesSection/conferenceCube.svg"
        alt=""
      />
      <div class="title">
        <h1>KONFERENCJA</h1>
        <h1>I DESIGN</h1>
        <div class="conference-more-info">
          <h2>23-24. LISTOPADA 2022 r.</h2>
          <h2>Wrocław stacjonarnie i online</h2>
          <p>
            JEDYNE TAKIE WYDARZENIE ŁĄCZĄCE CHARYTATYWNE DZIAŁANIA Z NAUKĄ I
            WIEDZĄ EKSPERCKĄ W DZIEDZINIE HUMAN EXPERIENCE
            <button class="default-button" style="display: none">
              ZAREJESTRUJ SIĘ
            </button>
          </p>
        </div>
      </div>
      <div class="women-photo">
        <img
          class="small-circle"
          src="../assets/conferencesSection/smallCircle.svg"
          alt=""
        />
        <img
          class="big-circle"
          src="../assets/conferencesSection/bigCircle.svg"
          alt=""
        />
        <div class="photo">
          <img src="../assets/conferencesSection/circlePhoto.svg" alt="" />
        </div>
      </div>
      <swiper
        class="info-baner"
        :loop="true"
        :autoplay="{ delay: 0 }"
        :speed="2500"
        :modules="modules"
        :breakpoints="{
          '375': {
            slidesPerView: 1,
            spaceBetween: 0,
            centeredSlides: true,
          },
          '525': {
            slidesPerView: 2,
            spaceBetween: 180,
            centeredSlides: true,
          },
          '740': {
            slidesPerView: 3,
            spaceBetween: 300,
            centeredSlides: true,
          },
          '1024': {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          '1400': {
            slidesPerView: 5,
            spaceBetween: 320,
          },
          '1600': {
            slidesPerView: 5,
            spaceBetween: 200,
          },
          '1800': {
            slidesPerView: 5,
            spaceBetween: 0,
          },
        }"
      >
        <swiper-slide class="first-info"
          ><h1 class="first-info">INFO</h1></swiper-slide
        >
        <swiper-slide class="first-info"
          ><h1 class="second-info">INFO</h1></swiper-slide
        >
        <swiper-slide class="first-info"
          ><h1 class="first-info">INFO</h1></swiper-slide
        >
        <swiper-slide class="first-info"
          ><h1 class="second-info">INFO</h1></swiper-slide
        >
      </swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";
import "swiper/scss";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/ConferencesSection";
</style>
