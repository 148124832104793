<template>
  <section class="previous-edition-section" id="previous-edition">
    <div class="previous-edition-bg">
      <h2 class="previous-edition-title">Poprzednie Edycje</h2>
      <p class="previous-edition-description">
        W ciągu 5 poprzednich edycji zorganizowaliśmy dla Was 45 wykładów i 25
        warsztatów, które cieszyły się zainteresowaniem ponad 10 tysięcy
        odbiorców.
      </p>
      <p class="previous-edition-description">
        Ich tematyka była zawsze dopasowana do aktualnych trendów
        marketingowych.
      </p>
      <div class="timeline-box">
        <div class="timeline">
          <div class="circle">
            <div class="edition-info">
              <h2 class="edition-title">2021 rok - V edycja</h2>
              <p class="edition-more-info">
                Kolejna edycja odbyła się w formie online. Na dwudniowej
                konferencji poruszaliśmy tematy z dziedziny marketingu. Nie
                zabrakło również praktycznej wiedzy, którą uczestnicy zdobyli
                podczas 3 warsztatów. V edycja przyniosła rekordową liczbę
                sprzedanych biletów.
              </p>
              <p class="edition-more-info">
                Zebraliśmy
                <span class="blue-bold-text"
                  >15 000 zł dla Fundacji Przylądek Nadziei.</span
                >
              </p>
            </div>
          </div>
          <div class="circle">
            <div class="edition-info">
              <h2 class="edition-title">2020 rok - IV edycja</h2>
              <p class="edition-more-info">
                2020 rok zarówno dla nas jak i dla wielu firm był ciężkim
                czasem. Pandemia, brak środków na dodatkową reklamę, oszczędność
                firm sprawiała, że nie wiedzieliśmy czy uda nam się zorganizować
                konferencję.
              </p>
              <p class="edition-more-info">
                Jednakudało się i w październiku
                <span class="blue-bold-text">
                  przenieśliśmy ją do świata online</span
                >.Warto podkreślić, że wystąpili na niej najpopularniejsi
                eksperci w Polsce.
              </p>
            </div>
          </div>
          <div class="circle">
            <div class="edition-info">
              <h2 class="edition-title">2019 rok - III edycja</h2>
              <p class="edition-more-info">
                Trzecia edycja była wyjątkowa. Postawiliśmy na płatne bilety, z
                których dochód był wpłacany na Przylądek Nadziei. Przenieśliśmy
                się do Centrum Rozwoju Zawodowego Krzywy Komin.
              </p>
              <p class="edition-more-info">
                Dzięki tym działaniom podbiliśmy zarówno świadomość naszej marki
                jak i połączyliśmy przyjemne z pożytecznym
              </p>
              <p class="edition-more-info">
                Zebraliśmy
                <span class="blue-bold-text"
                  >15 000 zł dla Fundacji Przylądek Nadziei.</span
                >
              </p>
            </div>
          </div>
          <div class="circle">
            <div class="edition-info">
              <h2 class="edition-title">2018 rok - II edycja</h2>
              <p class="edition-more-info">
                Powiększyliśmy liczbę uczestników dwukrotnie!<br />
                W tej edycji skupiliśmy się mocno na
                <span class="blue-bold-text">tematyce marketingowej.</span>
              </p>
              <p class="edition-more-info">
                Konferencja trwała dwa dni i nie zabrakło w niej wartościowego
                networkingu osób z całej Polski.
              </p>
            </div>
          </div>
          <div class="circle">
            <div class="edition-info">
              <h2 class="edition-title">2017 rok - I edycja</h2>
              <p class="edition-more-info">
                Podczas tworzenia projektów dla koła naukowego Design Thinking
                działającego przy Uniwersytecie Ekonomicznym we Wrocławiu
                narodził się pomysł stworzenia konferencji łączącej marketerów
                ze zwolennikami myślenia projektowego.
              </p>
              <p class="edition-more-info">
                Pierwsza edycja była ogromnym sukcesem - łączyła w sobie
                wykłady, warsztaty a także gre miejską.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../assets/style/PreviousEditionSection.scss";
</style>
