<template>
  <section class="charity-section">
    <a href="https://miedzyniebemaziemia.pl/" target="_blank">
      <img
        class="charity-logo"
        src="../assets/charitySection/charityLogo.svg"
        alt=""
      />
    </a>
    <p class="charity-info">
      W tym roku pomagamy fundacji
      <a
        class="fundation-name-color"
        href="https://miedzyniebemaziemia.pl/"
        target="_blank"
        >Między Niebem a Ziemią</a
      >, której podopiecznymi są nieuleczalnie chore dzieci. Jej misją jest, jak
      sama komunikuje - podarowanie kawałka nieba na ziemi. Fundacja pomaga
      między innymi w zakupie sprzętu rehabilitacyjnego, środków higienicznych,
      opłaceniu kosztownych operacji, leczenia, a także realizuje pomoc prawną i
      psychologiczną. Pomaga rodzinom podopiecznych
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; w aktywizacji
      zawodowej oraz wspierając profesjonalną opiekę nad nieuleczalnie chorymi.
    </p>
    <h3 class="support-fundation">
      Wesprzyj fundację - weź udział w konferencji
    </h3>
    <button class="default-button">ZAREJESTRUJ SIĘ</button>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../assets/style/CharitySection.scss";
</style>
