<template>
  <div>
    <nav>
      <ul class="nav-hamburger-links" :class="{ openMenu: isMobile }">
        <div class="icon-back" @click="showMenu"></div>
        <li>
          <router-link :to="{ name: 'home', hash: '#speakers' }"
            >PRELEGENCI</router-link
          >
        </li>
        <li :to="{ name: 'home', hash: '#program' }">PROGRAM</li>
        <li><router-link to="/">CENNIK</router-link></li>
        <li><router-link to="/strefa-wiedzy">STREFA WIEDZY</router-link></li>
        <li><router-link to="/o-nas">O NAS</router-link></li>
      </ul>
      <div
        class="nav-hamburger"
        @click="showMenu"
        :class="{ hideHamburger: hideHamburger }"
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="logo">
        <router-link to="/"
          ><img src="../assets/logo.svg" alt="I-DESIGN logo"
        /></router-link>
      </div>
      <ul class="nav-links">
        <li>
          <router-link :to="{ name: 'home', hash: '#speakers' }"
            >PRELEGENCI</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'home', hash: '#program' }"
            >PROGRAM</router-link
          >
        </li>
        <li><router-link to="/">CENNIK</router-link></li>
        <li><router-link to="/strefa-wiedzy">STREFA WIEDZY</router-link></li>
        <li><router-link to="/o-nas">O NAS</router-link></li>
      </ul>
    </nav>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false,
      hideHamburger: false,
    };
  },
  methods: {
    showMenu() {
      this.isMobile = !this.isMobile;
      this.hideHamburger = !this.hideHamburger;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/MainNav.scss";
</style>
