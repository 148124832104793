<template>
  <footer class="footer-section">
    <div class="back-icon">
      <img
        class="arrow-icon"
        ref="arrow"
        @click="scrollUp()"
        src="../assets/footer/back-icon.svg"
        alt=""
      />
    </div>
    <div class="first-row">
      <div class="footer-links">
        <div class="footer-nav">
          <h3 class="footer-links-title">POZNAJ</h3>
          <ul class="links-list">
            <li class="link"><router-link to="/o-nas">O NAS</router-link></li>
            <li class="link">
              <a href="/statut/statut_idesign.pdf" target="_blank">STATUT</a>
            </li>
            <li class="link">
              <a
                href="/polityka_prywatnosci/POLITYKA_PRYWATNOSCI_OMH.pdf"
                target="_blank"
                >POLITYKA PRYWATNOŚCI</a
              >
            </li>
            <li class="link">WARSZTATY</li>
            <li class="link">
              <router-link :to="{ name: 'about', hash: '#previous-edition' }"
                >POPRZEDNIE EDYCJE</router-link
              >
            </li>
            <li class="contact-link">
              <router-link :to="{ name: 'about', hash: '#contact-section' }"
                >KONTAKT</router-link
              >
            </li>
          </ul>
        </div>
        <div class="support">
          <h3 class="footer-links-title">WESPRZYJ</h3>
          <ul class="links-list">
            <li class="link" v-if="iDesign">STOWARZYSZENIE I DESIGN</li>
            <li class="link">
              <router-link :to="{ name: 'home', hash: '#charity-section' }"
                >NASZ CEL CHARYTATYWNY</router-link
              >
            </li>
            <li class="link">
              <a
                href="/zostan_wolontariuszem/zostan_wolontariuszem.png"
                target="_blank"
                >ZOSTAŃ WOLONTARIUSZEM</a
              >
            </li>
            <li class="link">
              <router-link
                :to="{ name: 'home', hash: '#begin-speaker-section' }"
                >ZOSTAŃ PRELEGENTEM</router-link
              >
            </li>
            <li class="link">
              <a href="https://patronite.pl/www.i-design.com.pl" target="_blank"
                >COMIESIĘCZNA WPŁATA</a
              >
            </li>
          </ul>
        </div>
      </div>
      <form class="footer-form" action="">
        <h3 class="footer-links-title">BĄDŹ NA BIEŻĄCO</h3>
        <input class="input-form-text" type="text" placeholder="IMIĘ" />
        <input class="input-form-text" type="text" placeholder="ADRES EMAIL" />
        <div class="form-radio">
          <input class="radio-type" type="checkbox" />
          <p class="radio-info">
            Wyrażam zgodę na otrzymywanie informacji marketingowych,
            promocyjnych i reklamowych od Stowarzyszenia I DESIGN z siedzibą we
            Wrocławiu, ul. Stefana Drzewieckiego 10/1, 54- 129 Wrocław wpisane
            do rejestru Stowarzyszeń pod numerem KRS 0000897986, numer NIP
            8943167568. Więcej informacji znajdziesz w naszej Polityce
            Prywatności
          </p>
        </div>
        <button class="default-button">ZAPISZ SIĘ NA NEWSLETTER</button>
      </form>
    </div>
    <div class="second-row">
      <img class="footer-logo" src="../assets/footer/logoFooter.svg" alt="" />
      <div class="social-icons">
        <a href="https://www.facebook.com/KONFERENCJAIDESIGN/"
          ><img class="icon-fb" src="../assets/footer/facebook-icon.svg" alt=""
        /></a>
        <a href="https://www.instagram.com/konferencjaidesign/"
          ><img class="icon" src="../assets/footer/instagram-icon.svg" alt=""
        /></a>

        <a
          href="https://www.linkedin.com/company/konferencjaidesign/?originalSubdomain=pl"
          ><img class="icon" src="../assets/footer/linkedin-icon.svg" alt=""
        /></a>
      </div>
      <div class="association-more-info">
        <p>2022 by Stowarzyszenie I DESIGN</p>
        <p>NIP: 894-316-75-68</p>
        <p>ul. Drzewieckiego 10/1, 54-129 Wrocław</p>
        <div class="authors">
          <p>Designed by Konstancja Tanjga</p>
          <p>Implemented by Krzysztof Chodziak</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      iDesign: false,
    };
  },
  methods: {
    scrollUp() {
      document.body.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/Footer.scss";
</style>
