<template>
  <AboutUsBeginInfo />
  <SocialResponsibilitySection />
  <PreviousEditionSection />
  <MeetUsSection />
  <ContactSection />
</template>

<script>
import AboutUsBeginInfo from "../components/AboutUsBeginInfo.vue";
import SocialResponsibilitySection from "../components/SocialResponsibilitySection.vue";
import PreviousEditionSection from "../components/PreviousEditionSection.vue";
import MeetUsSection from "../components/MeetUsSection.vue";
import ContactSection from "../components/ContactSection.vue";
export default {
  components: {
    AboutUsBeginInfo,
    SocialResponsibilitySection,
    PreviousEditionSection,
    MeetUsSection,
    ContactSection,
  },
};
</script>

<style></style>
