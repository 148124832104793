<template>
  <section class="not-found-section">
    <div class="info-container">
      <h1 class="info-title">Ups!</h1>
      <h2 class="info">My już też tu szukaliśmy</h2>
      <h2 class="more-info">
        Wygląda na to, że tej strony naprawdę nie ma ...
      </h2>
      <div class="main-button">
        <router-link to="/"
          ><img class="button-arrow" src="../assets/NotFound/arrow.svg" alt=""
        /></router-link>
        <span class="button-info">Wracajmy na stronę główną</span>
      </div>
    </div>
    <img class="person-foto" src="../assets/NotFound/person.svg" alt="" />
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.not-found-section {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(90deg, #6868ac 90%, #ffffff 10%);
  .info-container {
    background: #6868ac;
    position: relative;
    .info-title {
      position: relative;
      font-size: 230px;
      line-height: 276px;
      color: #ee3a5b;
      opacity: 1;
      margin-left: 5rem;
      z-index: 1;
      @media screen and (max-width: 1595px) {
        margin-left: 3rem;
      }
      @media screen and (max-width: 1375px) {
        font-size: 200px;
      }
      @media screen and (max-width: 1275px) {
        font-size: 230px;
        margin-left: 6rem;
      }
      @media screen and (max-width: 860px) {
        font-size: 180px;
        margin-left: 3rem;
      }
      @media screen and (max-width: 469px) {
        font-size: 150px;
      }
      @media screen and (max-width: 385px) {
        margin-left: 2rem;
      }
    }
    .info {
      position: absolute;
      margin-left: 18rem;
      width: 750px;
      color: #e7d0d0;
      font-size: 111px;
      line-height: 133px;
      z-index: 1;
      @media screen and (max-width: 1670px) {
        margin-left: 10rem;
      }
      @media screen and (max-width: 1540px) {
        margin-left: 12rem;
        font-size: 90px;
      }
      @media screen and (max-width: 1458px) {
        margin-left: 8rem;
      }
      @media screen and (max-width: 1375px) {
        font-size: 70px;
      }
      @media screen and (max-width: 1275px) {
        margin-left: 8.9rem;
      }
      @media screen and (max-width: 1080px) {
        margin-left: 5.3rem;
      }
      @media screen and (max-width: 860px) {
        margin-left: 3rem;
        font-size: 60px;
        width: 600px;
      }
      @media screen and (max-width: 841px) {
        width: auto;
      }
      @media screen and (max-width: 791px) {
        font-size: 44px;
      }
      @media screen and (max-width: 602px) {
        font-size: 35px;
      }
      @media screen and (max-width: 492px) {
        font-size: 30px;
      }
      @media screen and (max-width: 415px) {
        font-size: 25px;
      }
    }
    .more-info {
      color: #e7d0d0;
      font-weight: 400;
      font-size: 55px;
      line-height: 66px;
      margin-top: 20rem;
      margin-left: 6rem;
      position: absolute;
      width: 750px;
      z-index: 1;
      @media screen and (max-width: 1595px) {
        margin-left: 3rem;
      }
      @media screen and (max-width: 1540px) {
        font-size: 40px;
      }
      @media screen and (max-width: 1290px) {
        font-size: 30px;
        width: 550px;
      }
      @media screen and (max-width: 1290px) {
        font-size: 36px;
        width: 490px;
      }
      @media screen and (max-width: 1080px) {
        margin-left: 1rem;
      }
      @media screen and (max-width: 841px) {
        margin-top: 8rem;
        margin-left: 3rem;
      }
      @media screen and (max-width: 602px) {
        font-size: 28px;
      }
      @media screen and (max-width: 451px) {
        font-size: 25px;
        width: auto;
        padding-left: 1rem;
        padding-right: 1rem;
      }
      @media screen and (max-width: 385px) {
        margin-left: 2rem;
      }
    }
    .main-button {
      position: relative;
      z-index: 1;
      margin-top: 32rem;
      margin-left: 20rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .button-arrow {
        @media screen and (max-width: 1345px) {
          width: 60px;
        }
        @media screen and (max-width: 1010px) {
          width: 40px;
        }
      }
      .button-info {
        color: #262e6c;
        font-weight: 400;
        font-size: 30px;
        line-height: 36px;
        width: 220px;
        margin-left: 2rem;
        @media screen and (max-width: 1345px) {
          font-size: 25px;
        }
        @media screen and (max-width: 1010px) {
          font-size: 18px;
          width: 180px;
          margin-left: 1rem;
        }
      }
      @media screen and (max-width: 1595px) {
        margin-left: 12rem;
      }
      @media screen and (max-width: 1458px) {
        margin-left: 6rem;
      }
      @media screen and (max-width: 1412px) {
        margin-left: 1rem;
      }
      @media screen and (max-width: 1290px) {
        margin-left: 0;
      }
      @media screen and (max-width: 1275px) {
        justify-content: flex-start;
        margin-left: 2rem;
      }
      @media screen and (max-width: 1080px) {
        margin-left: 1rem;
      }
      @media screen and (max-width: 841px) {
        margin-top: 20rem;
        margin-left: 11rem;
      }
      @media screen and (max-width: 689px) {
        margin-left: 9rem;
      }
      @media screen and (max-width: 618px) {
        margin-left: 4rem;
      }
      @media screen and (max-width: 529px) {
        margin-left: 1rem;
      }
    }
    @media screen and (max-width: 1275px) {
      background: none;
    }
  }
  .person-foto {
    position: absolute;
    right: 0;
    @media screen and (max-width: 1275px) {
      position: relative;
      margin-top: -40rem;
    }
    @media screen and (max-width: 841px) {
      margin-top: -10rem;
    }
  }
  @media screen and (max-width: 1275px) {
    flex-direction: column;
    background: linear-gradient(90deg, #6868ac 29%, #ffffff 29%);
  }
}
</style>
