<template>
  <ConferenceSection />
  <BenefitsSection />
  <CharitySection />
  <SpeakerSection />
  <BeginSpeakerSection />
  <SponsorsSection />
</template>

<script>
import WorkshopSection from "../components/WorkshopSection.vue";
import ConferenceSection from "../components/ConferencesSection.vue";
import BenefitsSection from "../components/BenefitsSection.vue";
import CharitySection from "../components/CharitySection.vue";
import BeginSpeakerSection from "../components/BeginSpeakerSection.vue";
import SpeakerSection from "../components/SpeakersSection.vue";
import ProgramSection from "../components/ProgramSection.vue";
import SponsorsSection from "../components/SponsorsSection.vue";

export default {
  components: {
    WorkshopSection,
    ConferenceSection,
    BenefitsSection,
    CharitySection,
    BeginSpeakerSection,
    SpeakerSection,
    ProgramSection,
    SponsorsSection,
  },
};
</script>
