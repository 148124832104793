<template>
  <section class="social-responsibility-section">
    <img
      class="baner"
      src="../assets/SocialResponsibilitySection/baner.svg"
      alt=""
    />
    <img
      class="begin-elements"
      src="../assets/SocialResponsibilitySection/begin_elements.svg"
      alt=""
    />
    <h2 class="social-responsibility-title">
      <span style="color: #e7d0d0">Społeczna </span>
      <span class="red-text">odpowiedzialność </span> w biznesie to nasz główny
      cel.
    </h2>
    <h2 class="volunteers">Jesteśmy wolontariuszami</h2>
    <p class="volunteers-info">
      Jako wolontariusze Stowarzyszenia I DESIGN, działamy
      <span class="red-text">dla wspólnego dobra, non-profit</span>, nie
      pobierając w zamian korzyści pieniężnych. Naszą największą satysfakcją
      jest realna pomoc, jaką możemy nieść potrzebującym i wiedza, którą
      zdobywają uczestnicy wydarzenia.
    </p>
    <p class="volunteers-info">
      W tym roku biorący udział w konferencji bezpośrednio wspierają
      podopiecznych Fundacji Między Niebem a Ziemią.
      <span class="red-text"
        >Dochód uzyskany ze sprzedaży biletów na wydarzenie przekazywany jest w
        całości Fundacji.</span
      >
    </p>
    <h3 class="support-our-activities">
      WESPRZYJ NASZE DZIAŁANIA I ROZWÓJ STOWARZYSZENIA
    </h3>
    <div class="mission-vision-container">
      <h3 class="leader-event">
        <span class="red-text">Jesteśmy liderem eventów</span> z dziedziny Human
        Experience
      </h3>
      <p class="about-sponsors">
        Dzięki dobroci i szlachetności Sponsorów, już po raz szósty organizujemy
        profesjonalne wydarzenie, będące połączeniem warsztatów i dwudniowej
        konferencji hybrydowej. Wsparcie Sponsorów pomaga nam pokryć koszty
        administracyjne, promocyjne oraz realnie rozwijać Stowarzyszenie.
        Jesteśmy profesjonalistami - przekazujemy wiedzę na eksperckim poziomie.
      </p>
      <img
        class="circle-photo"
        src="../assets/SocialResponsibilitySection/o_nas.png"
        alt=""
      />
      <div class="mission-vision-row">
        <div class="mission-vision-column">
          <h3 class="mission-vision-title">Misja</h3>
          <ul class="mission-vision-examples">
            <li>edukujemy</li>
            <li>inspirujemy</li>
            <li>pomagamy</li>
          </ul>
        </div>
        <div class="mission-vision-column">
          <h3 class="mission-vision-title">Wizja</h3>
          <ul class="mission-vision-examples">
            <li>
              tworzymy kulturę nieszablonowego myślenia, inspirowania i
              przełamywania barier przez dzielenie się wiedzą
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../assets/style/SocialResponsibilitySection.scss";
</style>
