<template>
  <section class="benefits-begin-info">
    <h3>
      Myślenie projektowe to bez wątpienia kompetencja przyszłości. Sprawdzi się
      tam, gdzie niezbędne jest dokładne rozpoznanie potrzeb ludzi oraz
      doświadczeń.
    </h3>
    <div class="more-info">
      <h3>
        Podczas dwóch dni konferencji poruszymy trzy ścieżki tematyczne z
        zakresu
        <span class="red-text"
          >DESIGN THINKING, USER EXPERIENCE i CHANGE MANAGEMENT.</span
        >
      </h3>
      <h3>
        VI edycja I DESIGN to aż
        <span class="red-text">12 wystąpień ekspertów + 2 debaty,</span> na
        których poruszymy najbardziej nurtujące kwestie.
      </h3>
      <h3>
        Zapraszamy wszystkich związanych z marketingiem na dwudniową konferencję
        pełną doświadczeń!
      </h3>
    </div>
    <h3 class="info-details">
      Już dziś zarezerwuj sobie termin 23-24 LISTOPADA 2022. Szczegóły
      <b>dotyczące programu, miejsca i zapisów</b> podamy wkrótce.
    </h3>
    <div class="benefits-container">
      <div class="benefits-circles">
        <div class="first-row">
          <img
            class="workshop-circle"
            src="../assets/benefitsCircles/workshop-circle.svg"
            alt=""
          />
          <img
            class="conference-circle"
            src="../assets/benefitsCircles/conference-circle.svg"
            alt=""
          />
        </div>
        <div class="second-row">
          <img
            class="networking-circle"
            src="../assets/benefitsCircles/networking-circle.svg"
            alt=""
          />
          <img
            class="dimaq-circle"
            src="../assets/benefitsCircles/dimaq-circle.svg"
            alt=""
          />
        </div>
        <div class="third-row">
          <img
            class="knowledge-circle"
            src="../assets/benefitsCircles/knowledge-sharing.svg"
            alt=""
          />
          <img
            class="nobel-goal"
            src="../assets/benefitsCircles/nobel-goal.svg"
            alt=""
          />
          <img
            class="income-circle"
            src="../assets/benefitsCircles/income-circle.svg"
            alt=""
          />
        </div>
      </div>
      <div class="charity-title" id="charity-section">
        <h1>CHARYTATYWNIE</h1>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../assets/style/BenefitsSection.scss";
</style>
