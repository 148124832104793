<template>
  <section class="begin-speaker-section" id="begin-speaker-section">
    <img
      class="section-cubes"
      src="../assets/BeginSpeakerSection/cubes.svg"
      alt=""
    />
    <div class="begin-speaker-container">
      <div class="begin-speaker-title">
        <h2 class="speaker-title">Zostań naszym partnerem</h2>
      </div>
      <div class="begin-speaker-info">
        <img
          class="speaker-photo"
          src="../assets/BeginSpeakerSection/speaker-foto.svg"
          alt=""
        />
        <div class="begin-speaker-info-text">
          <h3 class="info-text">
            Jeśli jesteś <span class="red-text">firmą</span> dla której ważni są
            ludzie.
          </h3>
          <h3 class="info-text">
            Dołącz do nas i odkryj przed innymi swoją
            <span class="red-text">innowacyjność</span> i kreatywność.
          </h3>
          <h3 class="info-text">
            Podejmij się współpracy przy VI edycji I DESIGN i dołóż swoją
            cegiełkę.
          </h3>
        </div>
      </div>
      <button class="default-button">
        <router-link :to="{ name: 'about', hash: '#contact-section' }"
          >SKONTAKTUJ SIĘ</router-link
        >
      </button>
      <img
        class="section-big-cubes"
        src="../assets/BeginSpeakerSection/big-cubes.svg"
        alt=""
      />
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../assets/style/BeginSpeakerSection.scss";
</style>
