<template>
  <section class="about-us-begin-section">
    <img
      class="begin-baner"
      src="../assets/AboutUsBeginInfo/baner.svg"
      alt=""
    />
    <h3 class="our-goals">
      Mamy wspólny cel - pomagać a jednocześnie stawać się ekspertami,
      nieustannie podnosić poziom wiedzy i dzielić się tym z innymi.
    </h3>
    <div class="about-conference">
      <p class="about-conference-info">
        W trakcie szóstej już edycji organizowanej przez nas konferencji
        postanowiliśmy wrócić do korzeni i skupić się na tematyce
        <span class="red-text">design thinking,</span>
        czyli metodyki stosowanej w projektowaniu &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        i wdrażaniu nowych rozwiązań. Design to przede wszystkim funkcjonalność
        i skuteczne rozwiązywanie problemów.<br /><br />
        Design Thinking integruje wiele dziedzin tj.: technologii, techniki,
        ergonomii, ekonomii, marketingu, psychologii, socjologii czy zarządzania
        w celu kreowania innowacyjnej wartości dla odbiorcy.
      </p>
      <p class="conference-goals">
        <span class="red-text">Celem naszej konferencji</span> jest rozwinięcie
        najbardziej pożądanych kompetencji na rynku pracy, czyli: krytycznego
        myślenia, kreatywności oraz samodzielnego rozwiązywania problemów.
        Wydarzenie jest kierowane do osób głodnych praktycznej wiedzy z zakresu
        marketingu, którzy wiążą z nim przyszłość.
      </p>
    </div>
    <h2 class="support-us-title" id="test">Wesprzyj nasze działania</h2>

    <div class="support-us-options">
      <p class="support-option">
        <img
          src="../assets/AboutUsBeginInfo/circles.svg
      "
          alt=""
        />
        <span class="become-speakers">Zostań wolontariuszem</span>
      </p>
      <p class="support-option">
        <a href="https://patronite.pl/www.i-design.com.pl">
          <img
            src="../assets/AboutUsBeginInfo/patronite-icon.svg
      "
            alt=""
          />
          Dokonaj wpłaty na <span class="patronite">PATRONITE</span>
        </a>
      </p>
      <p class="support-option">
        <img
          src="../assets/AboutUsBeginInfo/cube.svg
      "
          alt=""
        />
        <span class="buy-ticket">Kup bilet na konferencję</span>
      </p>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
@import "../assets/style/AboutUsBeginInfo.scss";
</style>
