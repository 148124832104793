<template>
  <section>
    <div class="background-elements">
      <div class="main-info">
        <div class="instructor">
          <div class="photo">
            <img src="" alt="" />
          </div>
        </div>
        <div class="workshop-title">
          <h3>warsztaty</h3>
          <h1>DESIGN SAFARI</h1>
          <h2>- inkluzywny i dostępny design</h2>
          <div class="workshop-data">
            <h3>15. marca 2022 r.</h3>
            <h3>godz. 16:00-19:00, "Barbara"</h3>
            <h3>ul. Świdnicka 8b, Wrocław</h3>
          </div>
        </div>
      </div>
      <div class="more-info">
        <h3>
          Poprowadzi <span style="color: #ee3a5b">Justyna Turek</span> -
          konsultantka zrównoważonego rozwoju, projektantka, badaczka,
          inicjatorka Design Safari.
        </h3>
        <h3>
          Weź udział w warsztacie i dowiedz się jak projektować odpowiadając na
          potrzeby człowieka. Jak tworzyć produkty i usługi, które nie
          wykluczają.
        </h3>
      </div>
      <div class="more-info">
        <h2>
          Warsztat jest jednym z trzech wydarzeń towarzyszących listopadowej
          konferencji I Design.
        </h2>
        <span>
          <h2>Liczba miejsc jest ograniczona</h2>
          <h2>Szczegóły i zapisy TUTAJ.</h2>
        </span>
      </div>
      <div class="sign-up">zarejestruj się</div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
section {
  background: #262e6c;
  h1,
  h2,
  h3 {
    color: #f6f6f6;
  }
  .background-elements {
    display: flex;
    flex-direction: column;
    background-image: url("../assets/icons/workshopBg.svg");
    background-repeat: no-repeat;
    background-position-y: -80px;
    background-size: 100%;
    .main-info {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .instructor {
        position: relative;
        width: 30rem;
        height: 30rem;
        margin-top: 4%;
        margin-left: 3%;
        &::before {
          z-index: 1;
          position: absolute;
          content: "";
          top: 10%;
          left: -15%;
          background-image: url("../assets/icons/big-circle.svg");
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
        }
        &::after {
          z-index: 1;
          position: absolute;
          content: "";
          left: 65%;
          top: 70%;
          background-image: url("../assets/icons/small-circle.svg");
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
        }
        .photo {
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          overflow: hidden;

          &::before {
            content: "";
            position: absolute;
            left: -20%;
            top: 20%;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              156.54deg,
              #ee3a5b 28.81%,
              #262e6c 116.66%
            );
            filter: blur(100px);
            border-radius: 50%;
          }
          img {
            width: 100%;
            position: absolute;
            bottom: -15px;
          }
        }
      }
      .workshop-title {
        margin-top: 5%;
        margin-right: 5%;
        text-align: right;
        display: flex;
        flex-direction: column;
        h1 {
          opacity: 1;
          font-size: 100px;
        }
        h2 {
          font-weight: 400;
          font-size: 40px;
          line-height: 20px;
        }
        h3 {
          margin-bottom: 3rem;
        }
        .workshop-data {
          margin-top: 8rem;
          align-self: flex-end;
          h3 {
            padding-right: 4rem;
            font-size: 24px;
            margin-bottom: 0.5rem;
            text-align: left;
            color: rgba(238, 58, 91, 1);
          }
        }
      }
    }
    .more-info {
      margin-top: 5rem;
      margin-right: 10rem;
      display: flex;
      align-self: flex-end;
      justify-content: space-between;
      width: 50%;
      h3,
      h2 {
        color: #e7d0d0;
      }
      h3 {
        width: 300px;
        font-size: 20px;
      }
      h2 {
        font-size: 16px;
        width: 300px;
        font-weight: 300;
        line-height: 24px;
      }
      span {
        h2 {
          margin-bottom: 2rem;
        }
      }
    }
    .sign-up {
      width: 300px;
      text-transform: uppercase;
      text-align: center;
      color: white;
      background: #ee3a5b;
      padding: 1rem;
      border-radius: 30px;
      align-self: flex-end;
      margin-right: 8%;
      margin-top: 3%;
      margin-bottom: 3%;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
